<template>
  <div class="about">
    <div class="long-about">
      <h1>Where Tradition Meets Modern.</h1>
      <p>
        The Westerly was founded in 2023, right here in the heart of Draper. 
        We set out with one goal in mind, to create a barbershop that delivers 
        not only in high quality haircuts and service, but where hospitality is 
        the core of everything we do. A barbershop is more than a haircut to us, 
        we believe it's a unique experience and a place where thousands of people 
        can come together to connect, all while looking and feeling their best at 
        the end of each service. Whether you're looking for a tight skin fade, a 
        traditional style, or an edgier razor cut, our barbers are dedicated and 
        trained to get it how you want it.
      </p>
      <p>We look forward to having you in our shop!</p>
    </div>
    <short-about></short-about>
  </div>
</template>

<script>
import ShortAbout from "../ShortAbout.vue";
export default {
  components: { ShortAbout },
  name: "About",
};
</script>

<style scoped>
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
}

.long-about {
  background-color: white;
  color: black;
  padding-top: 70px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.long-about h1 {
  font-size: 36pt;
  margin-bottom: 25px;
  color: #3b1416;
}
</style>