<template>
  <div class="pic-container">
    <div class="side">
      <div class="filler"></div>
      <div class="side-flex">
        <div class="pricing-table">
          <div class="pricing-table-row">
            <div>Adult Haircut</div>
            <div>$40</div>
          </div>
          <div class="pricing-table-row">
            <div>Senior Cut (65+)</div>
            <div>$35</div>
          </div>
          <div class="pricing-table-row">
            <div>Kids Cut (3-12)</div>
            <div>$35</div>
          </div>
          <div class="pricing-table-row">
            <div>Beard Trim/Shave</div>
            <div>$30</div>
          </div>
          <div class="pricing-table-row">
            <div>Hair and Beard/Shave</div>
            <div>$60</div>
          </div>
        </div>
        <div class="actions">
          <a class="btn btn-lg btn-dark mt-1" href="https://westerly-barber-company.square.site">Book Now</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
};
</script>

<style scoped>
.pic-container {
  background-image: url("@/assets/img/image2.crop.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.side {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  padding-top: 250px;
  padding-bottom: 250px;

  height: 100%;
}

.filler {
  /* width:10vw; */
  flex-grow: 2;
}

.side-flex {
  padding-left: 0;
  padding-right: 5vw;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(233, 233, 233, 0.19);

  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (min-width: 601px) {
  .side-flex {
    padding-left: 5vw;
    padding-right: 5vw;
    width: inherit;
  }
}

.pricing-table {
  /* display: flex; */
  color: white;
  font-size: x-large;
  text-shadow: 2px 2px 5px black;
}

.actions {
  display: flex;
  justify-content: center;
}

.pricing-table li {
  list-style-type: none;
}

.pricing-table-row {
  display: flex;
  justify-content: space-between;
}

.pricing-table-row > div {
  margin-left: 20px;
}
</style>