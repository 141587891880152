<template>
  <div class="pic-container">
    <!-- <div class="pic"></div> -->
    <div class="logo-box">
      <img src="@/assets/img/brand/logo.png" />
      <div>
        <a class="btn btn-lg btn-dark" href="https://westerly-barber-company.square.site">Book Now</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",
};
</script>

<style scoped>
/* .pic {
  background-image: url("@/assets/img/image0.crop.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top 30px bottom 0px ;

  /* height: 75vh; */
  /* filter: blur(50px);
} */

.pic-container {
  background-image: url("@/assets/img/image1.crop.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;
  height: 50vh;
}

.logo-box {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */

  padding-top: 10vh;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;

  /* background-color: rgba(255,255,255,0.35); */

  height: 100%;
}

.logo-box > img {
  width: 100%;

  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5);
  transform-origin: center;

  background-color: rgba(255,255,255,0.75);

  padding: 30px;
  border-radius: 20px;
}

@media screen and (min-width: 601px) {
  .logo-box a {
    position: relative;
    top: -15vh;
  }
  .logo-box > img {
    /* margin-top: -2vh; */
    /* margin-bottom: -2vh; */
  }
}

.logo-box a {
  position: relative;
  top: -5vh;
}
</style>